import httpClient from "../../clients/httpClient";
import { IInviteStaffToOrganizationPayload } from "../../interfaces/organization.interface";
import { IUserInvitation } from "../../interfaces/user-invitation.interface";
import { IUser } from "../../interfaces/user.interface";
import { IAdminOrganizationQuery } from "../../state/types/admin-organization";

export const getStaff = (query?: IAdminOrganizationQuery) => {
    if (query?.id) {
        return httpClient.get(`/api/v1/admin/organizations/${query.id}/staff`, {
            params: {
                page: query?.page,
                per_page: query?.perPage,
            }
        });
    }

    return httpClient.get(`/api/v1/organizations/staff`, {
        params: {
            page: query?.page,
            per_page: query?.perPage,
        }
    });
}

export const getInvitedStaff = () => {
    return httpClient.get(`/api/v1/invitations`);
}

export const removeStaff = (organization: number | undefined, staff: number) => {
    return httpClient.patch(`/api/v1/organizations/${organization}/staff/${staff}`);
}
export const organizationRemoveStaff = (staff: number) => {
    return httpClient.delete(`/api/v1/organizations/staff/${staff}`);
}

export const inviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.post('/api/v1/admin/invitations/', payload);
}

export const organizationInviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.post<IUserInvitation | IUser>('/api/v1/invitations', payload);
}

export const organizationResendInviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.patch<IUserInvitation | IUser>(`/api/v1/invitations/${[payload.id]}`, payload);
}

export const organizationCancelInvite = (id: number) => {
    return httpClient.delete(`/api/v1/invitations/${id}`);
}

export const cancelInvite = (id: number) => {
    return httpClient.delete(`/api/v1/admin/invitations/${id}`);
}
