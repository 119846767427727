import {
    Category2,
    Shop,
    Flash,
    NotificationStatus,
    MessageText,
    Tag,
    Element4,
    Global,
    CopySuccess,
    Profile2User,
    TicketStar,
    Gift,
    Calendar,
    I3Dcube,
    ColorsSquare,
    BagTick,
    PresentionChart,
    Setting2,
    TagUser,
    UserAdd,
    Game,
    Coin
} from "iconsax-react";
import { RouteItem, UserTypes } from "../../interfaces/routes.interface";

import { ROUTES } from "../../constants/routes";

import AuthGuard from "../../layouts/AuthGuard";
import { lazy } from "react";
import createIconWrapper from "../../components/shared/IconWrapper";

const OrganizationSettings = lazy(
    () => import("../../../modules/organization/settings")
);
const OrganizationDashboard = lazy(
    () => import("../../../modules/organization/dashboard")
);
const Stores = lazy(() => import("../../../modules/organization/stores"));
const Form = lazy(() => import("../../../modules/organization/stores/Form"));
const IntegrationForm = lazy(
    () => import("../../../modules/organization/integrations/Form")
);
const GeofenceForm = lazy(
    () => import("../../../modules/organization/geofences/Form")
);
const OrganizationAchievementForm = lazy(
    () => import("../../../modules/organization/achievements/Form")
);
const OrganizationAchievements = lazy(
    () => import("../../../modules/organization/achievements")
);
const Notifications = lazy(
    () => import("../../../modules/organization/notifications")
);
const ProductReviews = lazy(
    () => import("../../../modules/organization/product-reviews")
);
const ProductReviewForm = lazy(
    () => import("../../../modules/organization/product-reviews/Form")
);
const Promotions = lazy(
    () => import("../../../modules/organization/promotions")
);
const PromotionForm = lazy(
    () => import("../../../modules/organization/promotions/Form")
);
const PunchcardForm = lazy(
    () => import("../../../modules/organization/punchcards/Form")
);
const RewardForm = lazy(
    () => import("../../../modules/organization/rewards/Form")
);
const Integrations = lazy(
    () => import("../../../modules/organization/integrations")
);
const Geofences = lazy(() => import("../../../modules/organization/geofences"));
const OrganizationManagement = lazy(
    () => import("../../../modules/organization/organization")
);
const Punchcards = lazy(
    () => import("../../../modules/organization/punchcards")
);
const Customers = lazy(() => import("../../../modules/organization/customers"));
const Rewards = lazy(() => import("../../../modules/organization/rewards"));
const CustomerProfile = lazy(
    () =>
        import(
            "../../../modules/organization/customers/profile/CustomerProfile"
        )
);
const Coupons = lazy(() => import("../../../modules/organization/coupons"));
const CouponsForm = lazy(
    () => import("../../../modules/organization/coupons/Form")
);
const ReferralsReport = lazy(
    () => import("../../../modules/organization/reports/ReferralsReport")
);
const TenantOrders = lazy(() => import("../../../modules/organization/orders"));
const Games = lazy(() => import("../../../modules/organization/games"));
const UserRegistrationReport = lazy(
    () => import("../../../modules/organization/reports/UserRegistrationReport")
);
const DailyRewards = lazy(
    () => import("../../../modules/organization/daily-rewards")
);
const DailyRewardForm = lazy(
    () => import("../../../modules/organization/daily-rewards/Form")
);

const MicroCurrencies = lazy(() => import("../../../modules/organization/micro-currency"))
const MicroCurrenciesForm = lazy(() => import("../../../modules/organization/micro-currency/Form"))

const userTypes = [UserTypes.user];
const roles = ["Organization Admin", "Organization User"] as any;
const orderRoles = [
    "Organization Admin",
    "Organization User",
    "Budtender",
] as any;

export const overview: RouteItem = {
    name: "Overview",
    id: ROUTES.USER.overview.key,
    path: ROUTES.USER.overview.key,
    component: OrganizationDashboard,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Category2, { variant: "Linear" }),
    iconActive: createIconWrapper(Category2, { variant: "Bold" }),
};

export const stores: RouteItem = {
    name: "Stores",
    id: ROUTES.USER.stores.key,
    path: ROUTES.USER.stores.key,
    component: Stores,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Shop, { variant: "Linear" }),
    iconActive: createIconWrapper(Shop, { variant: "Bold" }),
};

export const storesEdit: RouteItem = {
    name: "Stores Edit",
    id: ROUTES.USER.stores_edit.key,
    path: ROUTES.USER.stores_edit.key,
    component: Form,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "edit",
    },
    hidden: true,
};

export const storesCreate: RouteItem = {
    name: "Stores Create",
    id: ROUTES.USER.stores_create.key,
    path: ROUTES.USER.stores_create.key,
    component: Form,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "create",
    },
    hidden: true,
};

export const achievements: RouteItem = {
    name: "Achievements",
    id: ROUTES.USER.achievements.key,
    path: ROUTES.USER.achievements.key,
    component: OrganizationAchievements,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Flash, { variant: "Linear" }),
    iconActive: createIconWrapper(Flash, { variant: "Bold" }),
};

export const achievementsEdit: RouteItem = {
    name: "Achievements Edit",
    id: ROUTES.USER.achievements_edit.key,
    path: ROUTES.USER.achievements_edit.key,
    component: OrganizationAchievementForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "edit",
    },
    hidden: true,
};

export const achievementsCreate: RouteItem = {
    name: "Achievements Create",
    id: ROUTES.USER.achievements_create.key,
    path: ROUTES.USER.achievements_create.key,
    component: OrganizationAchievementForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "create",
    },
    hidden: true,
};

export const notifications: RouteItem = {
    name: "Notifications",
    id: ROUTES.USER.notifications.key,
    path: ROUTES.USER.notifications.key,
    component: Notifications,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(NotificationStatus, { variant: "Linear" }),
    iconActive: createIconWrapper(NotificationStatus, { variant: "Bold" }),
};

export const productReviews: RouteItem = {
    name: "Product Reviews",
    id: ROUTES.USER.product_reviews.key,
    path: ROUTES.USER.product_reviews.key,
    component: ProductReviews,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(MessageText, { variant: "Linear" }),
    iconActive: createIconWrapper(MessageText, { variant: "Bold" }),
};

export const productReviewsEdit: RouteItem = {
    name: "Product Reviews Edit",
    id: ROUTES.USER.product_reviews_edit.key,
    path: ROUTES.USER.product_reviews_edit.key,
    component: ProductReviewForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "edit",
    },
    hidden: true,
};

export const productReviewsCreate: RouteItem = {
    name: "Product Reviews Create",
    id: ROUTES.USER.product_reviews_create.key,
    path: ROUTES.USER.product_reviews_create.key,
    component: ProductReviewForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "create",
    },
    hidden: true,
};

export const promotions: RouteItem = {
    name: "Promotions",
    id: ROUTES.USER.promotions.key,
    path: ROUTES.USER.promotions.key,
    component: Promotions,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Tag, { variant: "Linear" }),
    iconActive: createIconWrapper(Tag, { variant: "Bold" }),
};

export const promotionsEdit: RouteItem = {
    name: "Promotions Edit",
    id: ROUTES.USER.promotions_edit.key,
    path: ROUTES.USER.promotions_edit.key,
    component: PromotionForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "edit",
    },
};

export const promotionsCreate: RouteItem = {
    name: "Promotions Create",
    id: ROUTES.USER.promotions_create.key,
    path: ROUTES.USER.promotions_create.key,
    component: PromotionForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "create",
    },
};

export const integrations: RouteItem = {
    name: "Integrations",
    id: ROUTES.USER.integrations.key,
    path: ROUTES.USER.integrations.key,
    component: Integrations,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Element4, { variant: "Linear" }),
    iconActive: createIconWrapper(Element4, { variant: "Bold" }),
};

export const integrationsEdit: RouteItem = {
    name: "Integrations Edit",
    id: ROUTES.USER.integrations_edit.key,
    path: ROUTES.USER.integrations_edit.key,
    component: IntegrationForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "edit",
    },
    hidden: true,
};

export const integrationsCreate: RouteItem = {
    name: "Integrations Create",
    id: ROUTES.USER.integrations_create.key,
    path: ROUTES.USER.integrations_create.key,
    component: IntegrationForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "create",
    },
    hidden: true,
};

export const geofences: RouteItem = {
    name: "Geofences",
    id: ROUTES.USER.geofences.key,
    path: ROUTES.USER.geofences.key,
    component: Geofences,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Global, { variant: "Linear" }),
    iconActive: createIconWrapper(Global, { variant: "Bold" }),
};

export const geofencesEdit: RouteItem = {
    name: "Geofences Edit",
    id: ROUTES.USER.geofences_edit.key,
    path: ROUTES.USER.geofences_edit.key,
    component: GeofenceForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "edit",
    },
    hidden: true,
};

export const geofencesCreate: RouteItem = {
    name: "Geofences Create",
    id: ROUTES.USER.geofences_create.key,
    path: ROUTES.USER.geofences_create.key,
    component: GeofenceForm,
    guard: AuthGuard,
    userTypes,
    roles,
    props: {
        mode: "create",
    },
    hidden: true,
};

export const settings: RouteItem = {
    name: "Settings",
    id: ROUTES.USER.settings.key,
    path: ROUTES.USER.settings.key,
    component: OrganizationSettings,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Setting2, { variant: "Linear" }),
    iconActive: createIconWrapper(Setting2, { variant: "Bold" }),
    isSecondaryItem: true,
};

export const organizationManagement: RouteItem = {
    name: "Organization",
    id: ROUTES.USER.organization_management.key,
    path: ROUTES.USER.organization_management.key,
    component: OrganizationManagement,
    userTypes,
    roles,
    guard: AuthGuard,
    icon: createIconWrapper(I3Dcube, { variant: "Linear" }),
    iconActive: createIconWrapper(I3Dcube, { variant: "Bold" }),
    isSecondaryItem: false,
};

export const punchcards: RouteItem = {
    name: "Punchcards",
    id: ROUTES.USER.punchcards.key,
    path: ROUTES.USER.punchcards.key,
    component: Punchcards,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(CopySuccess, { variant: "Linear" }),
    iconActive: createIconWrapper(CopySuccess, { variant: "Bold" }),
};

export const punchcardsCreate: RouteItem = {
    name: "Punchcard Create",
    id: ROUTES.USER.punchcards_create.key,
    path: ROUTES.USER.punchcards_create.key,
    component: PunchcardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "create",
    },
};

export const punchcardsEdit: RouteItem = {
    name: "Punchcard Edit",
    id: ROUTES.USER.punchcards_edit.key,
    path: ROUTES.USER.punchcards_edit.key,
    component: PunchcardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "edit",
    },
};

export const customers: RouteItem = {
    name: "Customers",
    id: ROUTES.USER.customers.key,
    path: ROUTES.USER.customers.key,
    component: Customers,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Profile2User, { variant: "Linear" }),
    iconActive: createIconWrapper(Profile2User, { variant: "Bold" }),
};

export const customersProfile: RouteItem = {
    name: "Customer Profile",
    id: ROUTES.USER.customers_profile.key,
    path: ROUTES.USER.customers_profile.key,
    component: CustomerProfile,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const rewards: RouteItem = {
    name: "Rewards",
    id: ROUTES.USER.rewards.key,
    path: ROUTES.USER.rewards.key,
    component: Rewards,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Gift, { variant: "Linear" }),
    iconActive: createIconWrapper(Gift, { variant: "Bold" }),
};

export const rewardsCreate: RouteItem = {
    name: "Reward Edit",
    id: ROUTES.USER.rewards_create.key,
    path: ROUTES.USER.rewards_create.key,
    component: RewardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "create",
    },
};

export const rewardsEdit: RouteItem = {
    name: "Reward Edit",
    id: ROUTES.USER.rewards_edit.key,
    path: ROUTES.USER.rewards_edit.key,
    component: RewardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "edit",
    },
};

export const microCurrencies: RouteItem = {
    name: "Micro-Currencies",
    id: ROUTES.USER.micro_currencies.key,
    path: ROUTES.USER.micro_currencies.key,
    component: MicroCurrencies,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Coin, { variant: "Linear" }),
    iconActive: createIconWrapper(Coin, { variant: "Bold" }),
};

export const microCurrenciesCreate: RouteItem = {
    name: "Micro-Currencies Create",
    id: ROUTES.USER.micro_currencies_create.key,
    path: ROUTES.USER.micro_currencies_create.key,
    component: MicroCurrenciesForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "create",
    },
};

export const microCurrenciesEdit: RouteItem = {
    name: "Micro-Currencies Edit",
    id: ROUTES.USER.micro_currencies_edit.key,
    path: ROUTES.USER.micro_currencies_edit.key,
    component: MicroCurrenciesForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "edit",
    },
};


export const gamification: RouteItem = {
    name: "Gamification",
    id: ROUTES.USER.gamification.key,
    path: ROUTES.USER.gamification.key,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(ColorsSquare, { variant: "Linear" }),
    iconActive: createIconWrapper(ColorsSquare, { variant: "Bold" }),
    subItems: [rewards, microCurrencies],
};

export const coupons: RouteItem = {
    name: "Coupons",
    id: ROUTES.USER.coupons.key,
    path: ROUTES.USER.coupons.key,
    component: Coupons,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(TicketStar, { variant: "Linear" }),
    iconActive: createIconWrapper(TicketStar, { variant: "Bold" }),
};

export const couponsCreate: RouteItem = {
    name: "Coupons Create",
    id: ROUTES.USER.coupons_create.key,
    path: ROUTES.USER.coupons_create.key,
    component: CouponsForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "create",
    },
};

export const couponsEdit: RouteItem = {
    name: "Coupons Edit",
    id: ROUTES.USER.coupons_edit.key,
    path: ROUTES.USER.coupons_edit.key,
    component: CouponsForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
    props: {
        mode: "edit",
    },
};

export const referralReport: RouteItem = {
    name: "Referrals",
    id: ROUTES.USER.referral_report.key,
    path: ROUTES.USER.referral_report.key,
    component: ReferralsReport,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(TagUser, { variant: "Linear" }),
    iconActive: createIconWrapper(TagUser, { variant: "Bold" }),
};

export const userRegistrationReport: RouteItem = {
    name: "User Registrations",
    id: ROUTES.USER.user_registration_report.key,
    path: ROUTES.USER.user_registration_report.key,
    component: UserRegistrationReport,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(UserAdd, { variant: "Linear" }),
    iconActive: createIconWrapper(UserAdd, { variant: "Bold" }),
};

export const reports: RouteItem = {
    name: "Reports",
    id: ROUTES.USER.reports.key,
    path: ROUTES.USER.reports.key,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(PresentionChart, { variant: "Linear" }),
    iconActive: createIconWrapper(PresentionChart, { variant: "Bold" }),
    subItems: [referralReport, userRegistrationReport],
};

export const orders: RouteItem = {
    name: "Orders",
    id: ROUTES.USER.orders.key,
    path: ROUTES.USER.orders.key,
    component: TenantOrders,
    guard: AuthGuard,
    userTypes,
    roles: orderRoles,
    icon: createIconWrapper(BagTick, { variant: "Linear" }),
    iconActive: createIconWrapper(BagTick, { variant: "Bold" }),
};

export const games: RouteItem = {
    name: "Games",
    id: ROUTES.USER.games.key,
    path: ROUTES.USER.games.key,
    component: Games,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Game, { variant: "Linear" }),
    iconActive: createIconWrapper(Game, { variant: "Bold" }),
};

export const dailyRewards: RouteItem = {
    name: "Daily Rewards",
    id: ROUTES.USER.daily_rewards.key,
    path: ROUTES.USER.daily_rewards.key,
    component: DailyRewards,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Calendar, { variant: "Linear" }),
    iconActive: createIconWrapper(Calendar, { variant: "Bold" }),
};

export const dailyRewardsCreate: RouteItem = {
    name: "Punchcard Create",
    id: ROUTES.USER.daily_rewards_create.key,
    path: ROUTES.USER.daily_rewards_create.key,
    component: DailyRewardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const dailyRewardsEdit: RouteItem = {
    name: "Punchcard Edit",
    id: ROUTES.USER.daily_rewards_edit.key,
    path: ROUTES.USER.daily_rewards_edit.key,
    component: DailyRewardForm,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const userRoutes: RouteItem[] = [
    overview,
    stores,
    storesEdit,
    storesCreate,
    achievements,
    achievementsEdit,
    achievementsCreate,
    notifications,
    productReviews,
    productReviewsEdit,
    productReviewsCreate,
    promotions,
    promotionsEdit,
    promotionsCreate,
    integrations,
    integrationsCreate,
    integrationsEdit,
    geofences,
    geofencesCreate,
    geofencesEdit,
    punchcards,
    punchcardsCreate,
    punchcardsEdit,
    customers,
    customersProfile,
    coupons,
    couponsCreate,
    couponsEdit,
    gamification,
    rewardsCreate,
    rewardsEdit,
    microCurrenciesCreate,
    microCurrenciesEdit,
    dailyRewards,
    dailyRewardsCreate,
    dailyRewardsEdit,
    settings,
    organizationManagement,
    orders,
    games,
    reports,
];

export const budtenderRoutes: RouteItem[] = [orders];
