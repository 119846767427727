import { ROUTES } from "../../constants/routes";
import { RouteItem } from "../../interfaces/routes.interface";
import { ITenantFeatureAttributes } from "../../interfaces/tenant-feature.interface";
import { adminRoutes } from "./admin-routes";

import { budtenderRoutes, userRoutes } from "./user-routes";

const validateRoute = (route: RouteItem, features?: ITenantFeatureAttributes) => {
    if (!features?.is_coupons_enabled && route.id === ROUTES.USER.coupons.key) return false;
    if (!features?.is_orders_enabled && route.id === ROUTES.USER.orders.key) return false;
    if (!features?.is_rewards_enabled && !features?.is_custom_rewards_enabled && route.id === ROUTES.USER.rewards.key) return false;
    if (!features?.is_micro_currency_enabled && route.id === ROUTES.USER.micro_currencies.key) return false;
    if (!features?.is_achievements_enabled && route.id === ROUTES.USER.achievements.key) return false;
    if (!features?.is_product_reviews_enabled && route.id === ROUTES.USER.product_reviews.key) return false;
    if (!features?.is_punchcards_enabled && route.id === ROUTES.USER.punchcards.key) return false;
    if (!features?.is_games_enabled && route.id === ROUTES.USER.games.key) return false;
    if (!features?.is_geofencing_enabled && route.id === ROUTES.USER.geofences.key) return false;
    if (!features?.is_daily_rewards_enabled && route.id === ROUTES.USER.daily_rewards.key) return false;
    if (!features?.is_referrals_enabled && [ROUTES.USER.reports.key, ROUTES.USER.referral_report.key].includes(route.id)) return false;
    if (!features?.is_rewards_enabled && !features?.is_micro_currency_enabled && route.id === ROUTES.USER.gamification.key) return false;
    return !route.hidden && !route.isSecondaryItem
}

export const getUserNavigation = (type: string, roleName: string, features?: ITenantFeatureAttributes) => {
    const adminSideBarPrimary = adminRoutes?.filter(
        (val) => !val.hidden && !val.isSecondaryItem
    );

    const adminSideBarSecondary = adminRoutes?.filter(
        (val) => !val.hidden && val.isSecondaryItem
    );

    let userSideBarItemsPrimary = userRoutes?.filter(
        (val) => {
            return validateRoute(val, features);
        }
    );

    userSideBarItemsPrimary = userSideBarItemsPrimary.map((val) => {
        if (val.subItems) {
            val.subItems = val.subItems.filter((subVal) => {
                return validateRoute(subVal, features);
            });
        }
        return val;
    });


    const userSideBarItemsSecondary = userRoutes?.filter(
        (val) => !val.hidden && val.isSecondaryItem
    );

    const budtenderSideBarItemsPrimary = budtenderRoutes?.filter(
        (val) => !val.hidden && !val.isSecondaryItem
    );

    if (roleName === 'Budtender') {
        return {
            primary: budtenderSideBarItemsPrimary,
            secondary: [],
        }
    }

    if (type === "admin") {
        return {
            primary: adminSideBarPrimary,
            secondary: adminSideBarSecondary,
        };
    } else {
        return {
            primary: userSideBarItemsPrimary,
            secondary: userSideBarItemsSecondary,
        };
    }
};
