import httpClient from "../../clients/httpClient";
import {IOrganizationPayload, IOrganizationResponse} from "../../interfaces/organization.interface";
import { GetAdminOrganizationsPayload } from "../../state/types/admin-organization";

export const getOrganizations = (query: GetAdminOrganizationsPayload) => {
    return httpClient.get<IOrganizationResponse>(`/api/v1/admin/organizations`, {
        params: {
            search: query.search,
            per_page: query.perPage,
            page: query.page,
            include: 'promotionSettings'
        }
    });
};

export const createOrganization = (payload: IOrganizationPayload) => {
    return httpClient.post("/api/v1/admin/organizations", payload);
};

export const editOrganization = (id: number, payload: IOrganizationPayload) => {
    return httpClient.patch(`/api/v1/admin/organizations/${id}`, payload);
};

export const deleteOrganization = (id: number) => {
    return httpClient.delete(`/api/v1/admin/organizations/${id}`);
};

export const getEmployees = (id: number) => {
  return httpClient.get(`/api/v1/admin/organizations/${id}`);
};

export const showOrganization = (id: string | number) => {
    return httpClient.get(`/api/v1/admin/organizations/${id}`)
};

export const uploadLogo = (id: number, payload: any) => {
    return httpClient.post(`/api/v1/admin/organizations/${id}/logo`, payload);
}
