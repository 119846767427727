import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import * as Sentry from '@sentry/react';


const BASEURL = import.meta.env.VITE_API_ENDPOINT;
const ORG_BASEURL = import.meta.env.VITE_ORG_API_ENDPOINT;

const controllers: any = {};

const httpClient = axios.create({
    baseURL: BASEURL,
});

httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const url = config.url
    const token = JSON.parse(
        localStorage.getItem("token") || "{}"
    )?.access_token;

    let org = localStorage.getItem("org");

    if (controllers[url as any]) {
        controllers[url as any].abort();
        console.log(`Aborted previous request to ${url}`);
    }

    return fetch(config.baseURL + '/api/v1/custom-domains')
        .then(response => response.json())
        .then(data => {
            const currentHostname = window.location.hostname;
            const domains = data.data; // Extract the domains object

            // Check if the current hostname exists in the domains object
            if (domains.hasOwnProperty(currentHostname)) {
                org = domains[currentHostname]; // Set org to the corresponding value
            }

            if (org) {
                if (import.meta.env.VITE_ENVIRONMENT === "development") {
                    config.baseURL = `http://${org}.${ORG_BASEURL}`;
                } else {
                    config.baseURL = `https://${org}.${ORG_BASEURL}`;
                }

                if (config.url?.includes("/auth/token") && !config.data["2fa"]) {
                    config.baseURL = BASEURL;
                }
            }
            console.log('Organization:', org);
            config.headers['Authorization'] = token ? `Bearer ${token}` : "";

            const controller = new AbortController();
            config.signal = controller.signal;
            controllers[url as any] = controller;

            return config; // Return the modified config
        })
        .catch(error => {
            console.error('Error fetching domains:', error);
            return config; // Ensure config is still returned in case of failure
        });
});

httpClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        return response.data;
    },
    (error) => {
        const url = error.config?.url;
        if (url) delete controllers[url];

        if (error.name === 'CanceledError') {
            console.log(`Request to ${url} was canceled`);

            return new Promise(() => {}); 
        }
        if (!error?.response || error?.response?.status !== 422) {
            try {
                Sentry.captureException(error);
            } catch (e) {
                // do nothing
            }
        }

        let errorMessage =
            error?.response?.data?.errors?.messages ??
            "Failed to complete your request.";
        let messages = "";

        if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors?.messages &&
            typeof error?.response?.data?.errors?.messages === "object" &&
            !Array.isArray(error?.response?.data?.errors?.messages)
        ) {
            for (const key in error?.response?.data?.errors?.messages) {
                if (Array.isArray(error?.response?.data?.errors?.messages[key])) {
                    messages +=
                        error?.response?.data?.errors?.messages[key].join("\n");
                }
            }
        } else if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors.messages &&
            Array.isArray(error?.response?.data?.errors?.messages)
        ) {
            messages += error?.response?.data?.errors?.messages?.join("\n");
        } else if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors?.messages &&
            typeof error?.response?.data?.errors?.messages === "string"
        ) {
            messages = error?.response?.data?.errors?.messages;
        } else {
            try {
                Object.keys(error?.response?.data?.errors).forEach((key) => {
                    toast.error(error?.response?.data?.errors[key].join("\n"));
                });
            } catch (e) {
                messages = errorMessage;
            }
        }

        if (messages === "Invalid Token") {
            messages =
                "Your invitation has expired, please contact the support.";
        }

        if (messages.trim().length) {
            toast.error(messages);
        }

        throw error;
    }
);

export default httpClient;
