import httpClient from "../../clients/httpClient";
import { ResetPasswordPayload } from "../../interfaces/auth.interface";

export const loginRequest = (payload: any) => {
    return httpClient.post("/oauth/token", {
        ...payload,
        client_id: import.meta.env.VITE_CLIENT_ID,
        client_secret: import.meta.env.VITE_CLIENT_SECRET,
        grant_type: "password",
        scope: "",
        is_web: true
    });
};

export const registerRequest = (payload: any) => {
    return httpClient.post("/api/v1/register", { ...payload });
};

export const getLoggedInUserRequest = () => {
    return httpClient.get("/api/v1/profile", {
        params: {
            include: 'organization,features,tenantSettings,role,storeRestrictions,promotionSettings'
        }
    });
};

export const forgotPasswordRequest = (email: string) => {
    return httpClient.post("/api/v1/web/password/email", { email });
};

export const resetPasswordRequest = (payload: ResetPasswordPayload) => {
    return httpClient.post("/api/v1/web/password/reset", payload);
};

export const tenantUserRegisterRequest = (payload: any) => {
    return httpClient.post("/api/v1/register/tenant", { ...payload });
};
