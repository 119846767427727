import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

interface ContextState {
    theme: "light" | "dark";
    isMobile: boolean;
    isSidebarOpen: boolean;
    setIsSidebarOpen: (isSidebarOpen: boolean) => void;
    updateTheme: (isDark: boolean) => void;
}

const defaultContext: ContextState = {
    theme: "light",
    isMobile: false,
    isSidebarOpen: false,
    setIsSidebarOpen: () => {},
    updateTheme: () => {},
};

const ThemeContext = createContext<ContextState>(defaultContext);

const ThemeContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [theme, setTheme] = useState<"light" | "dark">("light");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const [isTablet, setIsTablet] = useState(window.innerWidth < 900);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const theme = localStorage.getItem("theme");
        if (theme) {
            setTheme(theme as "light" | "dark");
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
            setIsTablet(window.innerWidth < 900);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


	const updateTheme = (isDark: boolean) => {
        const theme = isDark ? "dark" : "light";
        localStorage.setItem("theme", theme);
        setTheme(theme as "light" | "dark");
        document.documentElement.setAttribute("data-theme", theme);
		if(isDark) {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
		
    };

	return (
		<ThemeContext.Provider
			value={{
				theme,
				updateTheme,
				isMobile,
                isSidebarOpen: isSidebarOpen && isTablet,
                setIsSidebarOpen,
			}}>
			{children}
		</ThemeContext.Provider>
	);
};

const useThemeContext = () => useContext(ThemeContext);

export { ThemeContextProvider, useThemeContext };