import { AuthContext } from "../context/auth";
import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import Loader from "../components/Loader";
import { RoleTypes } from "../enums/roles.enum";

const AuthGuard = ({ roles, userTypes, path, children }: any) => {
    const navigate = useNavigate();
    const { authState } = useContext(AuthContext);

    const isAuthenticated = useMemo(() => {
        return !authState?.loading && authState?.user && authState?.token;
    }, [authState?.loading, authState?.user, authState?.token]);

    useEffect(() => {
        if (!authState?.loading && isAuthenticated && authState?.user) {
            const userType = authState?.user?.attributes?.type;
            const userRole =
                authState?.user?.relationships?.role?.attributes?.name;
            const isPermittedRole = roles?.includes(userRole);
            const isPermitedTypes = userTypes?.includes(userType);
            const features =
                authState?.user?.relationships?.organization?.relationships
                    ?.features?.attributes;
            const settings =
                authState?.user?.relationships?.organization?.relationships
                    ?.tenant_settings?.attributes;

            const hasBothTypesAndRoles =
                Boolean(roles?.length > 0) && Boolean(userTypes?.length > 0);

            if (
                userType === RoleTypes.user &&
                authState?.user?.relationships?.role?.attributes?.name !==
                "Budtender"
            ) {
                if (!features) {
                    return undefined;
                }

                if (
                    path === ROUTES.USER.geofences.key &&
                    !settings?.is_geofencing_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    path === ROUTES.USER.games.key &&
                    !settings?.is_games_active
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    path === ROUTES.USER.orders.key &&
                    !features.is_orders_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.achievements.key,
                        ROUTES.USER.achievements_create.key,
                        ROUTES.USER.achievements_edit.key,
                    ].includes(path) &&
                    !features.is_achievements_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.coupons.key,
                        ROUTES.USER.coupons_create.key,
                        ROUTES.USER.coupons_edit.key,
                    ].includes(path) &&
                    !features.is_coupons_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.rewards.key,
                        ROUTES.USER.rewards_edit.key,
                    ].includes(path) &&
                    !features.is_rewards_enabled && !features.is_custom_rewards_enabled
                ) {
                    // console.log('Guard', ROUTES.USER.rewards_edit.key, path);
                    return navigate(ROUTES.USER.overview.key);
                }

                if ([
                    ROUTES.USER.rewards_create.key,
                ].includes(path) && !features.is_custom_rewards_enabled) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [ROUTES.USER.rewards_create.key].includes(path) &&
                    !features.is_custom_rewards_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.punchcards.key,
                        ROUTES.USER.punchcards_create,
                        ROUTES.USER.punchcards_edit.key,
                    ].includes(path) &&
                    !features.is_punchcards_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.product_reviews.key,
                        ROUTES.USER.product_reviews_create.key,
                        ROUTES.USER.product_reviews_edit.key,
                    ].includes(path) &&
                    !features.is_product_reviews_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.reports.key,
                        ROUTES.USER.referral_report.key,
                    ].includes(path) &&
                    !features.is_referrals_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if (
                    [
                        ROUTES.USER.daily_rewards.key,
                        ROUTES.USER.daily_rewards_create.key,
                        ROUTES.USER.daily_rewards_edit.key,
                    ].includes(path) &&
                    !features.is_daily_rewards_enabled
                ) {
                    return navigate(ROUTES.USER.overview.key);
                }

                if ([ROUTES.USER.micro_currencies.key, ROUTES.USER.micro_currencies_create.key, ROUTES.USER.micro_currencies_edit.key].includes(path) && !features.is_micro_currency_enabled) {
                    return navigate(ROUTES.USER.overview.key);
                }
            }

            if (
                (hasBothTypesAndRoles && isPermittedRole && isPermitedTypes) ||
                (!hasBothTypesAndRoles && (isPermittedRole || isPermitedTypes))
            ) {
                return undefined;
            } else {
                if (userType === RoleTypes.admin) {
                    navigate(ROUTES.ADMIN.dashboard.key);
                }
                if (userType === RoleTypes.user) {
                    if (
                        authState?.user?.relationships?.role?.attributes
                            ?.name === "Budtender"
                    ) {
                        navigate(ROUTES.USER.orders.key);
                    } else {
                        navigate(ROUTES.USER.overview.key);
                    }
                }
            }
        }
        if (!authState?.loading && !isAuthenticated) {
            navigate(ROUTES.AUTH.login.key);
        }
    }, [isAuthenticated]);

    return (
        <>
            {authState?.loading ? (
                <div className="flex justify-center">
                    <Loader />
                </div>
            ) : (
                <> {children}</>
            )}
        </>
    );
};

export default AuthGuard;
