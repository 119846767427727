import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth";
import Loader from "../components/Loader";
import LogoLight from "../../../../assets/img/dm-logo-light.png";

const AuthLayout = ({ children }: { children?: React.ReactNode }) => {
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!authState?.loading && authState?.user && authState?.token) {
            navigate("/");
        }
    }, [authState?.loading, authState?.user, authState?.token]);

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center gap-8">
            <img src={LogoLight} alt="logo" className="w-40" />

            <div
                className={`bg-base-100 p-5 xl:p-10 rounded-lg shadow-2xl border border-line w-11/12 md:w-[500px] mx-auto`}
            >
                {!authState?.loading && children && children}
                {authState?.loading && <Loader />}
            </div>
        </div>
    );
};

export default AuthLayout;
