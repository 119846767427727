import {
    BellIcon,
    BriefcaseIcon,
    ChartPieIcon,
    CogIcon,
    CursorArrowRaysIcon,
    DocumentTextIcon,
    TagIcon,
    Squares2X2Icon,
} from "@heroicons/react/24/outline";
import httpClient from "../../clients/httpClient";
import {
    IUser,
    IUserPayload,
    IUserResponse,
} from "../../interfaces/user.interface";
import { GetAdminUsersPayload } from "../../state/types/admin-users";
import { useLocation } from "react-router-dom";

export const getUsers = (query: GetAdminUsersPayload) => {
    return httpClient.get<IUserResponse>(`/api/v1/admin/users`, {
        params: {
            per_page: query.perPage,
            page: query.page,
        },
    });
};

export const getUsersByRoles = (payload: string) => {
    return httpClient.get(`/api/v1/users`, { params: { role: payload } });
};

export const getUser = (id: string) => {
    return httpClient.get(`/api/v1/admin/users/${id}`, {
        params: {
            include: "permissions",
        },
    });
};

export const createUser = (payload: IUserPayload) => {
    return httpClient.post("/api/v1/admin/users", payload);
};

export const createOrganizationUser = (payload: IUserPayload) => {
    return httpClient.post("/api/v1/users", payload);
};

export const editUser = (id: number, payload: IUserPayload) => {
    return httpClient.patch(`/api/v1/admin/users/${id}`, payload);
};

export const editOrganizationUser = (id: number, payload: IUserPayload) => {
    return httpClient.patch(`/api/v1/users/${id}`, payload);
};

export const deleteUser = (id: number) => {
    return httpClient.delete(`/api/v1/admin/users/${id}`);
};

export const updateAvatar = (id: number, payload: any) => {
    return httpClient.post(`/api/v1/users/${id}/avatar`, payload);
};

export const updateUserPassword = (id: number, payload: any) => {
    return httpClient.patch(`/api/v1/users/${id}/password`, payload);
};

export const editProfile = (payload: IUserPayload) => {
    return httpClient.patch(`/api/v1/profile`, payload);
};

export const editProfileAvatar = (payload: IUserPayload) => {
    return httpClient.post(`/api/v1/profile/avatar`, payload);
};

export const editProfilePassword = (payload: IUserPayload) => {
    return httpClient.patch(`/api/v1/profile/password`, payload);
};

export const getUserNavigation = (type: string) => {
    const location = useLocation();
    return type === "user"
        ? {
              primary: [
                  {
                      name: "Overview",
                      href: "/overview",
                      icon: ChartPieIcon,
                      current: location?.pathname?.includes("/overview"),
                  },
                  {
                      name: "Stores",
                      href: "/stores",
                      icon: BriefcaseIcon,
                      current: location?.pathname?.includes("/stores"),
                  },
                  {
                      name: "Achievements",
                      href: "/achievements",
                      icon: BriefcaseIcon,
                      current: location?.pathname?.includes("/achievements"),
                  },
                  {
                      name: "Notifications",
                      href: "/notifications",
                      icon: BellIcon,
                      current: location?.pathname?.includes("/notifications"),
                  },
                  {
                      name: "Product Reviews",
                      href: "/product-reviews",
                      icon: DocumentTextIcon,
                      current: location?.pathname?.includes("/product-reviews"),
                  },
                  {
                      name: "Promotions",
                      href: "/promotions",
                      icon: TagIcon,
                      current: location?.pathname?.includes("/promotions"),
                  },
                  {
                      name: "Integrations",
                      href: "/integrations",
                      icon: Squares2X2Icon,
                      current: location?.pathname?.includes("/integrations"),
                  },
              ],
              secondary: [
                  {
                      name: "Settings",
                      href: "/settings",
                      icon: CogIcon,
                      current: location?.pathname?.includes("/settings"),
                  },
              ],
          }
        : {
              primary: [
                  {
                      name: "Overview",
                      href: "/overview",
                      icon: ChartPieIcon,
                      current: location?.pathname?.includes("/overview"),
                  },
                  {
                      name: "Organizations",
                      href: "/organizations",
                      icon: BriefcaseIcon,
                      current: location?.pathname?.includes("/organizations"),
                  },
                  {
                      name: "Permissions",
                      href: "/permissions",
                      icon: CursorArrowRaysIcon,
                      current: location?.pathname?.includes("/permissions"),
                  },
                  {
                      name: "Roles",
                      href: "/roles",
                      icon: CursorArrowRaysIcon,
                      current: location?.pathname?.includes("/roles"),
                  },
                  {
                      name: "Users",
                      href: "/users",
                      icon: CursorArrowRaysIcon,
                      current: location?.pathname?.includes("/users"),
                  },
              ],
              secondary: [
                  {
                      name: "Settings",
                      href: "/settings",
                      icon: CogIcon,
                      current: location?.pathname?.includes("/settings"),
                  },
              ],
          };
};

export const uploadUserID = (data: FormData, token: string) => {
    return httpClient.post<IUser>(`/api/v1/user/${token}/id`, data);
};
