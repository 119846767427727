import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IMainNavigation } from "../interfaces/navigation.interface";
import { AuthContext } from "../context/auth";
import { RightSideBarContext } from "../context/rightSideBar";
import { getUserNavigation } from "../../core/services/routes/index";
import SideBarItem from "../components/SideBarItem";
import Header from "../components/TopNavBar";
import Loader from "../components/Loader";
import { useThemeContext } from "../context/theme";
import TawkToScript from "./TawkToScript";

const SidebarLayout = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const { authState } = useContext(AuthContext);
    const { state: rightSideBarState } = useContext(RightSideBarContext);
    const { isSidebarOpen, setIsSidebarOpen, isMobile } = useThemeContext();
    const [navigation, setNavigation] = useState<IMainNavigation>({
        primary: [],
        secondary: [],
    });

    useEffect(() => {
        if (rightSideBarState?.refresh) {
            document.getElementById("rightSideDrawer")?.click();
        }
    }, [rightSideBarState?.refresh]);

    useEffect(() => {
        if (!authState.loading && authState && authState.user) {
            const { user } = authState;
            const type = user.relationships.role.attributes.type;
            const roleName = user.relationships.role.attributes.name;
            if (
                authState?.user?.relationships?.role?.attributes?.type ===
                "user" &&
                !authState.user
            ) {
                return;
            }
            setNavigation(
                getUserNavigation(
                    type,
                    roleName,
                    authState?.user?.relationships?.organization?.relationships
                        ?.features?.attributes
                ) as any
            );
        }
    }, [location?.pathname, authState.loading, authState.user]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <>
            <TawkToScript />
            <div className="drawer drawer-end">
                <input
                    id="rightSideDrawer"
                    type="checkbox"
                    className="drawer-toggle"
                />
                <div className="drawer-content">
                    <div className="drawer drawer-mobile">
                        <input
                            id="leftSideDrawer"
                            type="checkbox"
                            className="drawer-toggle"
                        />
                        <div className="drawer-content lg:!z-[1000]">
                            <Header toggleSidebar={toggleSidebar} />
                            <div className="flex h-[calc(100vh-64px)]">
                                <div
                                    className={`drawer-side border-r ${isSidebarOpen
                                        ? "min-w-[288px]"
                                        : "min-w-[50px] lg:min-w-[288px]"
                                        } bg-dark-gray lg:fixed lg:left-0 lg:top-[64px] lg:bottom-0 transition-all duration-300 relative`}
                                >
                                    <nav className="space-y-2 p-2 md:py-4 md:px-6 h-full overflow-y-auto text-base-content">
                                        <ul>
                                            {navigation.primary.map((item, idx) => (
                                                <SideBarItem
                                                    icon={item.icon as any}
                                                    name={item?.name}
                                                    iconActive={item.iconActive}
                                                    path={item?.path}
                                                    subItems={item.subItems}
                                                    key={idx}
                                                    isExpanded={isSidebarOpen}
                                                />
                                            ))}
                                        </ul>
                                        <hr />
                                        <ul>
                                            {navigation.secondary.map(
                                                (item, idx) => (
                                                    <SideBarItem
                                                        icon={item.icon}
                                                        name={item?.name}
                                                        iconActive={item.iconActive}
                                                        path={item?.path}
                                                        subItems={item.subItems}
                                                        key={idx}
                                                        isExpanded={isSidebarOpen}
                                                    />
                                                )
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                                <section
                                    className={`flex-1 overflow-y-auto transition-all duration-300 ${isSidebarOpen ? "pl-0" : "lg:pl-[288px]"
                                        }`}

                                    onClick={() => {
                                        if (isSidebarOpen && isMobile) {
                                            toggleSidebar();
                                        }
                                    }}
                                >
                                    {authState.loading ? (
                                        <div className={`flex justify-center h-full ${isMobile && isSidebarOpen ? 'invisible' : ''}`}>
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div className={`lg:py-6 lg:px-6 ${isMobile && isSidebarOpen ? 'invisible' : ''}`}>{children}</div>
                                    )}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer-side">
                    <label htmlFor="rightSideDrawer" className="drawer-overlay" />
                    <div className="p-10 overflow-y-auto w-4/5 lg:w-1/4 bg-base-100 text-base-content">
                        {rightSideBarState?.component}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarLayout;
