import httpClient from "../../clients/httpClient";
import { ISettingsPayload, ITenantSettings } from "../../interfaces/setting.interface";

export const getSettings = () => {
    return httpClient.get(`/api/v1/settings`);
};

export const updateSettings = (payload: ISettingsPayload) => {
    return httpClient.patch(`/api/v1/settings`, {
        settings: payload
    });
};

export const getTenantSettings = () => {
    return httpClient.get<ITenantSettings>(`/api/v1/tenant-settings`);
};

export const updateTenantSettings = (payload: FormData) => {
    return httpClient.post<ITenantSettings>(`/api/v1/tenant-settings`, payload);
};

