import { gamification } from "../services/routes/user-routes";

export const adminBasePath = "/admin";
export const authBasePath = "/auth";
export const ROUTES = {
    AUTH: {
        login: {
            key: authBasePath + "/",
        },
        register: {
            key: authBasePath + "/register",
        },
        register_with_otp: {
            key: authBasePath + "/register-with-otp",
        },
        two_factor_verification: {
            key: authBasePath + "/2fa",
        },
        forgot_password: {
            key: authBasePath + "/forgot-password",
        },
        reset_password: {
            key: authBasePath + "/reset-password",
            parse: (token: string) => authBasePath + `/reset-password`,
        },
    },
    PRODUCT: {
        product: {
            key: "/store/:store_id/product/:product_id/:menu_type/:user_token",
            parse: (store: string, product: string, menu: string, token: string) => `/store/${store}/product/${product}/${menu}/${token}`,
        },
        cart: {
            key: "/store/:store_id/cart/:menu_type/:user_token",
        },
        checkout: {
            key: "/checkout/:service/store/:store_id/:menu_type/:user_token",
        },
        thankyou: {
            key: "/checkout/:service/store/:store_id/:menu_type/:user_token/thank-you",
            parse: (
                store: string,
                menu: string,
                token: string,
                service: string
            ) =>
                `/checkout/${service}/store/${store}/${menu}/${token}/thank-you`,
        },
        profile: {
            key: "/checkout/profile",
        },
        game_stats: {
            key: "/game/results/:event_id/:token",
        },
    },
    ADMIN: {
        dashboard: {
            key: adminBasePath + "/overview",
        },
        organizations: {
            key: adminBasePath + "/organizations",
        },
        organization_management: {
            key: "/organization-management",
        },
        organizations_view: {
            key: adminBasePath + "/organizations/:id",
            parse: (id: number) => adminBasePath + `/organizations/${id}`,
        },
        organizations_features: {
            key: adminBasePath + "/organizations/:id/features",
            parse: (id: number) =>
                adminBasePath + `/organizations/${id}/features`,
        },
        permissions: {
            key: adminBasePath + "/permissions",
        },
        roles: {
            key: adminBasePath + "/roles",
        },
        users: {
            key: adminBasePath + "/users",
        },
        users_view: {
            key: adminBasePath + "/users/:id",
            parse: (id: number) => adminBasePath + `/users/${id}`,
        },
        profile: {
            key: adminBasePath + "/profile",
        },
        settings: {
            key: adminBasePath + "/settings",
        },
        logs: {
            key: adminBasePath + "/logs",
        },
        product_sync_logs: {
            key: adminBasePath + "/logs/product-sync",
        },
        promotion_sync_logs: {
            key: adminBasePath + "/logs/promotion-sync",
        },
        reward_sync_logs: {
            key: adminBasePath + "/logs/reward-sync",
        },
        notification_logs: {
            key: adminBasePath + "/logs/notification",
        },
        registration_and_verifications_logs: {
            key: adminBasePath + "/logs/registration-and-verification",
        },
    },
    USER: {
        overview: {
            key: "/overview",
        },
        organization_management: {
            key: "/organization-management",
        },
        notifications: {
            key: "/notifications",
        },
        stores: {
            key: "/stores",
        },
        stores_edit: {
            key: "/stores/:id",
            parse: (id: number, params?: string) =>
                `/stores/${id}${params ?? ""}`,
        },
        stores_create: {
            key: "/stores/create",
        },
        achievements: {
            key: "/achievements",
        },
        achievements_edit: {
            key: "/achievements/:id",
            parse: (id: number) => `/achievements/${id}`,
        },
        achievements_create: {
            key: "/achievements/create",
        },
        product_reviews: {
            key: "/product-reviews",
        },
        product_reviews_edit: {
            key: "/product-reviews/:id",
            parse: (id: number) => `/product-reviews/${id}`,
        },
        product_reviews_create: {
            key: "/product-reviews/create",
        },
        promotions: {
            key: "/promotions",
        },
        promotions_edit: {
            key: "/promotions/:id",
            parse: (id: number) => `/promotions/${id}`,
        },
        promotions_create: {
            key: "/promotions/create",
        },
        punchcards: {
            key: "/punchcards",
        },
        punchcards_create: {
            key: "/punchcards/create",
        },
        punchcards_edit: {
            key: "/punchcards/:id",
            parse: (id: number) => `/punchcards/${id}`,
        },
        coupons: {
            key: "/coupons",
        },
        coupons_create: {
            key: "/coupons/create",
        },
        coupons_edit: {
            key: "/coupons/:id",
            parse: (id: number) => `/coupons/${id}`,
        },
        integrations: {
            key: "/integrations",
        },
        integrations_create: {
            key: "/integrations/create",
        },
        integrations_edit: {
            key: "/integrations/:id",
            parse: (id: number) => `/integrations/${id}`,
        },
        geofences: {
            key: "/geofences",
        },
        geofences_create: {
            key: "/geofences/create",
        },
        geofences_edit: {
            key: "/geofences/:id",
            parse: (id: number) => `/geofences/${id}`,
        },
        customers: {
            key: "/customers",
        },
        customers_profile: {
            key: "/customers/:id",
            parse: (id: number) => `/customers/${id}`,
        },
        rewards: {
            key: "/rewards",
        },
        rewards_create: {
            key: "/rewards/create",
        },
        rewards_edit: {
            key: "/rewards/:id",
            parse: (id: number) => `/rewards/${id}`,
        },
        settings: {
            key: "/settings",
        },
        reports: {
            key: "/reports",
        },
        referral_report: {
            key: "/reports/referrals",
        },
        user_registration_report: {
            key: "/reports/user-registrations",
        },
        orders: {
            key: "/orders",
        },
        games: {
            key: "/games",
        },
        daily_rewards: {
            key: "/daily-rewards",
        },
        daily_rewards_edit: {
            key: "/daily-rewards/:id",
            parse: (id: number) => `/daily-rewards/${id}`,
        },
        daily_rewards_create: {
            key: "/daily-rewards/create",
        },
        gamification: {
            key: "/gamification",
        },
        micro_currencies: {
            key: "/micro-currencies",
        },
        micro_currencies_create: {
            key: "/micro-currencies/create",
        },
        micro_currencies_edit: {
            key: "/micro-currencies/:id",
            parse: (id: number) => `/micro-currencies/${id}`,
        },
    },
};
